
<template>
  <div class="music-detail-box container">
    <div class="detail-head">
      <div class="flex logo-info">
          <div class="logo_wrap" :style="imgBg(musicInfo.logo_url)" >
            <img src="@/assets/images/web/info_play.svg"
              v-if="!isPlaying(musicInfo.music_id)" 
              @click="onClickIcon(musicInfo)"
              class="playIncon"  />
            <img src="@/assets/images/web/pouse.svg"
              v-else
              @click="onPause(musicInfo.music_id)"
              class="playIncon"  /> 
          </div>
          <div class="info_wrap">
           <div class="i-name">{{ musicInfo.name }}</div>
            <div class="i-suplr_name">
              <router-link :to="`/supplierInfo/${musicInfo.suplr_id}`">
                <ng-image
                  class="header-thumbnail m-right-sm"
                  :src="musicInfo.logo_url"
                  alt="thumbnail"
                />
                <span>{{musicInfo.suplr_name}}</span>
              </router-link>
            </div>
            <div class="i-label" v-for="(item, index) in tags" :key="index">
              <span v-if="index == 0"> {{item.name}}</span>
              <span v-else>| {{item.name}}</span>
            </div>
            <div class="i-operation">
              <span class="i-download" @click="goDownloadMusic(musicInfo)">
                <img src="@/assets/images/web/info_download.svg" />
                下载
              </span>
              <img class="i-icon" src="@/assets/images/web/hover_cart.svg" @click="goAddMusicToShopCart(musicInfo)" alt="加购" />
              <img class="i-icon" src="@/assets/images/web/hover_collect.svg" v-if="musicInfo.is_collected == '0'" @click="collectMusic" alt="收藏" />
              <img class="i-icon-done" src="@/assets/images/web/collect_done.svg" @click="collectMusic" v-else alt="已收藏" />
              <img class="i-icon" @click="goShareMusic(musicInfo)"  src="@/assets/images/web/hover_share.svg" alt="分享" />
            </div>
          </div>
      </div>
      <div class="flex info-content">
      </div>
    </div>
    <div class="music-detail-bottom">
      <h2>相似音乐</h2>
      <music-table :request="$axios.Music_ListSimilars" :params="musicParams" is-more :pagination="false" :selecteLabelIds="selecteLabelIds"></music-table>
    </div>
  </div>
</template>

<script>
  import {Tag} from "ant-design-vue";
  import NgImage from "~/components/NgImage.vue";
  import musicHandle from '@/mixins/musicHandle'
  import PageTitleIcon from "@/pages/components/common/PageTitleIcon.vue";
  import MusicTable from '@/pages/components/music/NewMusicTableAllMusic.vue'

  import {UpdateMusicList} from '@/mixins/music'

  export default {
    data() {
      return {
        musicInfo: {},
        tags: [],
        music_id: '',
        musicParams: {},
        selecteLabelIds:[], //选中的标签ID api会用到
      };
    },
    mixins: [musicHandle, UpdateMusicList],
    components: {NgImage, ATag: Tag, PageTitleIcon, MusicTable},
    computed: {
      imgBg(url){
        return (url) =>{
          return {
            'background-image':`url(${url})`
          }
        }
      },
    },

    watch: {
      $route(n, o) {
        if (n.params.music_id != o.params.music_id) {
          this.music_id = n.params.music_id
          this.getMusicInfo();
        }
      }
    },

    created() {
      this.music_id = this.$route.params.music_id || '';
      this.getMusicInfo();
      this.musicParams = Object.assign({}, this.musicParams, {music_id: this.music_id});
    },

    mounted() { },

    methods: {
      isPlaying (now_id) {
        let state = this.$store.state
        if (!state.globalVideoInfo) return false
        let { music_id, url } = state.globalVideoInfo
        return (
          state.globalVideoPlaying &&
          music_id &&
          url &&
          now_id === music_id
        )
      },
      onClickIcon (item) {
        this.isPlaying(item.music_id) ? this.onPause() : this.onPlay(item)
        this.$store.commit('changeVideoList', this.list || [])
      },

      collectMusic() {
        this.goCollectMusic(this.musicInfo).then((scope) => {
          this.musicInfo = Object.assign({}, this.musicInfo, scope);
        });
      },

      getMusicInfo() {
        this.$axios
          .Music_Info({music_id: this.music_id})
          .then((res) => {
            const data = res.data;

            if (data && data.code == 0) {
              this.musicInfo = data.data[0] || {};

              let labels = this.getAllLabel(this.musicInfo.labels || []);

              this.tags = labels;
            }

            this.$store.commit("changeContentLoading", false);
          })
          .catch(() => {
            this.$store.commit("changeContentLoading", false);
          });
      },

      getAllLabel(data) {
        let arr = [];

        data.forEach(item => {
          arr = arr.concat(one(item.child));
        });

        return arr;

        function one(list) {
          return list.reduce((prev, next) => {
            if (next.child && next.child.length > 0) {
              prev.concat(one(next.child))
            }

            prev.push(next)

            return prev;
          }, [])
        }
      },

      onPlay() {
        let musicInfo = this.musicInfo;
        musicInfo.url = musicInfo.audition_url;
        this.$store.commit('changeVideoInfo', this.musicInfo);
        this.$nextTick(() => {
          this.$store.commit('changeVideoPlaying', true);
        })
      },
      onPause() {
        this.$store.commit('changeVideoPlaying', false);
      }
    }

  };
</script>

<style lang="scss" scoped>
  .music-detail-box {
    .detail-head{
      margin-bottom: 32px;
      margin-top: 32px;
      width: 100%;
      .logo-info {
        width: 100%;
        height: 180px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start; 
        .logo_wrap{
          width: 180px;
          height:180px;
          border-radius: 4px;
          background-position: center;
          transition: all .3s ease-in-out;
          background-size: 100%;
        }
        .logo_wrap:hover{
          cursor: pointer;
          background-size: 110%;
        }
        .info_wrap{
          width: 80%;
          margin-left: 24px;
          .i-name{
            height: 33px;
            font-size: 24px;
            font-weight: 500;
            color: #333333;
            line-height: 33px;
            margin-bottom: 12px;
          }
          .i-suplr_name{
            margin-bottom: 24px;
            .header-thumbnail {
              width: 28px;
              height: 28px;
            }
            span{
              height: 22px;
              font-size: 16px;
              font-weight: 400;
              color: #333333;
              line-height: 22px;
            }
          }
          .i-label{
            display: inline-block;
            margin-bottom: 27px;
            span{
              height: 20px;
              font-size: 14px;
              font-weight: 400;
              color: #666666;
              line-height: 20px;
              margin-right:8px;
            }
          }
          .i-operation{
            .i-download{
              display: inline-block;
              cursor: pointer;
              width:15%;
              height: 36px;
              line-height: 36px;
              background: #FF7B13;
              border-radius: 18px;
              text-align: center;
              font-size: 16px;
              font-weight: 400;
              color: #FFFFFF;
            }
            .i-icon, .i-icon-done{
              display: inline-block;
              margin-left: 17px;
              cursor:pointer;
            }
            .i-icon{
              opacity: 0.7;
              &:hover{
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }
  .playIncon{
    width: 48px;
    height: 48px;
    margin-top: 33%;
    margin-left: 40%;
  }
  // mobile
@media only screen and (max-width: $screen-width-md) {
  .music-detail-box .detail-head .logo-info{
    height: 130px;
    .logo_wrap{
      height: 130px;
    }
    .info_wrap{
      .i-name{
        font-size: 14px;
        margin: 0;
      }
      .i-suplr_name {
        margin: 0;
        .header-thumbnail{
          width: 14px;
          height: 14px;
        }
        span{
          font-size: 12px;
        }
      }
      .i-label{
        margin-bottom: 4px;
        span{
          font-size: 12px;
          height: 12px;
          line-height: 12px;
          margin:0;
        }
      }
      .i-operation{
        .i-download{
          display: block;
          width: 40%;
          height: 18px;
          line-height: 18px;
          font-size: 12px;
          img{
            width: 20%;
          }
        }
        .i-icon, .i-icon-done{
          width: 30px;
          height: 30px;
          margin: 0;
          margin-top: 8px;
          margin-right: 4px;
        }
      }
      
    }

  }
  .playIncon{
    width: 36px;
    height: 36px;
  }
}
</style>
